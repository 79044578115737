import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SolveLevel } from './solution';
import { assignLetters, cloneGrid, emptyGrid, findSuggestedWordLocations, getAvailableLetters, GridState, resetCells, setGridCellStates } from './grid/gridSlice';
import { disableUsedLetters, enableAllLetters, enableAvailableLetters, TypeButtonState } from './userInput/typeButtons/typeButtonsSlice';
import { generateRandomHint, HintState, HintType } from './userInput/hints/hintSlice';
import { BitReader, loadGrid, getGridFileReader, ENCODING_FULL_DELIMIT_VAR, ENCODING_FULL_COMPRESS_VAR } from '../api/loadGrid';
import { CellConditions, CellLocation } from './grid/cell/cellSlice';
import { wasWordLocationClicked, WordLocations } from './words';
import Cookies from 'universal-cookie';
import { clearWordTypedResetGrid } from './userInput/userInputSlice';
import { processWin } from './winScreen/winScrenSlice';
import { loadPlayHistory, UserPlayState } from './popout/history/historySlice';
import { getInitTutorialState, getNextStep, isStateAllowNextTutorialStep, isTutorialActionAllowed, TutorialState, TutorialStep } from './tutorial/tutorialSlice';

export type InitState = {
    finalSolution: GridState,
    lettersAvailable: string,
    gridId: string,
    startCells: Array<CellLocation>
}

export type CookieState = {
    gridId: string,
    words: Array<WordLocations>,
    hints: Array<HintState>,
    timerMs: number,
    timerHintDelta: number
}

export type FullState = {
    finalSolution: GridState,   // simply data to test against
    gridId: string,
    userDisplay: GridState,
    lettersAvailable: string,   //max [5,10] characters
    wordTyped: string,
    typeButtons: Array<TypeButtonState>,
    showAcceptableLetterHints: number,    //max letters that can be shown in a box as a hint
    hintsGiven: Array<HintState>,
    puzzleStartTime: number,
    puzzleEndTime: number,
    nextHintSeconds: number,
    wordTypedLocations: Array<WordLocations>,
    invalidWordTypedLocations: Array<WordLocations>,    //for when they type a word, but it doesn't fit due to row/col restrictions
    selectedWordIndex: number,
    selectedCell: CellLocation | null,
    win: boolean,
    boardLoaded: string,
    recentPlayedGames: Array<UserPlayState>,
    allIndexedWords: Record<string, Record<number, Array<string>>>,
    tutorial: TutorialState
}

export const cookies = new Cookies(null, {path: '/'})

function getInitUserDisplayState(finalSolution: GridState, hints: Array<HintState>) {
    let userDisplay: GridState = cloneGrid(finalSolution)
    for(let i=0; i<userDisplay.words.length; i++) {
        userDisplay.words[i].solveLevel = SolveLevel.hidden;
    }
    for (let row=0; row<userDisplay.cells.length; row++) {
        for(let col=0; col<userDisplay.cells[row].length; col++)
            userDisplay.cells[row][col].condition = CellConditions.blocked;
    }
    setGridCellStates(userDisplay, hints, [], -1, null, []);
    return userDisplay;
}

export function isDev() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        return true;
    return false;
}

function loadGame(state: FullState, puzzleBitReader: BitReader): FullState {

    //console.log(puzzleBitReader?.bits || "No Data");
    let initState: InitState = loadGrid(puzzleBitReader);
    resetCells(initState.finalSolution);
    assignLetters(initState.finalSolution)

    let hintsGiven: Array<HintState> = []
    for(let i=0; i<initState.startCells.length; i++) {
        hintsGiven.push({
            hintType: HintType.goodLetter,
            row: initState.startCells[i].row,
            col: initState.startCells[i].col,
            letters: initState.finalSolution.cells[initState.startCells[i].row][initState.startCells[i].col].text || '',
            horizontal: null,
            processed: false
        })
    }

    let displayGrid = getInitUserDisplayState(initState.finalSolution, hintsGiven)
    let now = new Date().getTime()

    let typeButtons: Array<TypeButtonState> = []
    for(let i=0; i<initState.lettersAvailable.length; i++) {
        if(initState.lettersAvailable[i] !== " ")
            typeButtons.push({
                letter: initState.lettersAvailable[i],
                enabled: true,
                lastClickTime: now
            })
    }

    //timers.timerId = setInterval(() => {store.dispatch(updateTimer())}, 1000)
    state.puzzleEndTime = 0;
    state.puzzleStartTime = Date.now();
    state.nextHintSeconds = 1;
    state.finalSolution = initState.finalSolution;
    state.gridId = initState.gridId;
    state.userDisplay = displayGrid;
    state.lettersAvailable = initState.lettersAvailable;
    state.wordTyped = "";
    state.typeButtons = typeButtons;
    state.showAcceptableLetterHints = initState.lettersAvailable.length/2;
    state.hintsGiven = hintsGiven;
    state.wordTypedLocations = [];
    state.invalidWordTypedLocations = [];
    state.win = false;
    state.selectedWordIndex = -1;
    state.selectedCell = null;


    if(applyCookieToState(state)) {
        clearWordTypedResetGrid(state);
        processWin(state, false);
    }

    return state;

}

function buildWord(groupings: Array<string>, fullGroups: Set<number>, doAllLettersLastGroup: boolean): Array<string> {
    let ret: Array<string> = []
    let buildStr: string = "";
    for(let ix=0; ix<groupings.length; ix++) {
        let group: string = groupings[ix];
        if(fullGroups.has(ix)) {
            buildStr += group;
            if(ix === groupings.length -1)
                ret.push(buildStr);
        }
        else if(ix === groupings.length-1) {
            if(doAllLettersLastGroup) {
                for(let j=0; j<group.length; j++) {
                    ret.push(buildStr + group[j]);
                }
            }
            else {
                ret.push(buildStr + group[group.length-1])
            }
        }
        else {
            buildStr += group[group.length-1]
        }
    }

    return ret;
}

function decompressWords(input: string): Array<string> {
    let ret: Array<string> = [];
    let groupings: Array<string> = [];
    let allLetterGroups = new Set<number>();
    let priorBuildCloseWord: boolean = false;
    let priorCharWasLetter: boolean = false;

    for(let i=0; i<input.length; i++) {
        let letter: string = input[i];
        if(letter === "(")
            groupings.push("");
        else if (letter === "[") {
            let tmp = buildWord(groupings, allLetterGroups, false)
            for(let x=0; x<tmp.length; x++)
                ret.push(tmp[x]);
            groupings.push("");
        } else if (letter === "{") {
            allLetterGroups.add(groupings.length);
            groupings.push("");
        } else if(letter === ']') {
            if(!priorBuildCloseWord) {
                let tmp = buildWord(groupings, allLetterGroups, false)
                for(let x=0; x<tmp.length; x++)
                    ret.push(tmp[x]);
            }
            groupings.pop();
            allLetterGroups.delete(groupings.length)
        } else if(allLetterGroups.has(groupings.length-1))
            groupings[groupings.length-1] += letter;
        else if (priorCharWasLetter) {
            let tmp = buildWord(groupings, allLetterGroups, false)
            for(let x=0; x<tmp.length; x++)
                ret.push(tmp[x]);
            groupings[groupings.length-1] = letter;
        } else {
            groupings[groupings.length-1] = letter;
        }

        priorBuildCloseWord = Boolean(letter === ']');
        if(["(", "]", "{", "["].indexOf(letter) === -1)
            priorCharWasLetter = true;
        else
            priorCharWasLetter = false;
    }

    return ret;
}

async function loadAllCompressedWords(): Promise<Record<string, Record<number, Array<string>>>> {
    let ret: Record<string, Record<number, Array<string>>> = {}
    let alpha="ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for(let i=0; i<alpha.length; i++) {
        let letter = alpha[i];
        ret[letter] = {}
        for(let j=1; j<=10; j++)
            ret[letter][j] = []
    }

    let fileLocation: string = "./wordList_compress_var.bin"
    let data2: Blob | null = null;

    try {
        data2 = await fetch(fileLocation) // works with file system and URLs. Sweet.
        .then( res => {
            //let cl = res.clone();
            //let t = res.text()
            //console.log(t)
            return res.blob();
        })
        if(data2?.type.toLowerCase() !== "application/octet-stream" && data2?.type.toLowerCase() !== "binary/octet-stream")
            return ret;
    } catch (e){
        //console.log(e);
    }
    if(data2 === null)
        return ret;

    let blob = new Uint8Array(await data2.arrayBuffer());
    let bd = new BitReader(blob);
    let decoded = bd.readEncoded(ENCODING_FULL_COMPRESS_VAR)
    let allWords = decompressWords(decoded.toUpperCase());  //upper to ensure matches dict above
    for(let i=0; i<allWords.length; i++) {
        let word = allWords[i];
        if(word.length > 0 && word.length <= 10)
            ret[word[0]][word.length].push(word);
    }

    return ret;
}


async function loadAllWords(): Promise<Record<string, Record<number, Array<string>>>> {

    let ret: Record<string, Record<number, Array<string>>> = {}
    let alpha="ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for(let i=0; i<alpha.length; i++) {
        let letter = alpha[i];
        ret[letter] = {}
        for(let j=1; j<=10; j++)
            ret[letter][j] = []
    }

    let fileLocation: string = "./wordList_del_var.bin"
    let data2: Blob | null = null;

    try {
        data2 = await fetch(fileLocation) // works with file system and URLs. Sweet.
        .then( res => {
            //let cl = res.clone();
            //let t = res.text()
            //console.log(t)
            return res.blob();
        })
        if(data2?.type.toLowerCase() !== "application/octet-stream" && data2?.type.toLowerCase() !== "binary/octet-stream")
            return ret;
    } catch (e){
        //console.log(e);
    }

    if(data2 === null)
        return ret;

    let blob = new Uint8Array(await data2.arrayBuffer());
    let bd = new BitReader(blob);
    let decoded = bd.readEncoded(ENCODING_FULL_DELIMIT_VAR)
    let wordList = decoded.split("\n")
    for(let i=0; i<wordList.length; i++) {
        let word = wordList[i];
        let letter = word[0];
        let length = word.length
        if(length <= 10)
            ret[letter][length].push(word);
    }
    return ret;
}

async function getInitialState(): Promise<FullState> {
    let now = new Date();
    const offset = now.getTimezoneOffset();
    let local = new Date(now.getTime() - offset*60*1000);
    let gridToLoad = local.toISOString().split('T')[0];
    let tutorial = loadCookie("tutorial")
    let tutDone = Boolean(tutorial === "DONE")
    if(!tutDone)
        gridToLoad = "tutorial"
    else {
        cookies.set("tutorial", "DONE", {maxAge: 60*60*24*365}) // reset the lifetime
    }

    let puzzleBitReader: BitReader | null = await getGridFileReader(gridToLoad);
    let everyWord = await loadAllCompressedWords();


    let state: FullState = {
        finalSolution: emptyGrid,
        gridId: "",
        userDisplay: emptyGrid,
        lettersAvailable: "",
        wordTyped: "",
        typeButtons: [],
        showAcceptableLetterHints: 2,
        hintsGiven: [],
        wordTypedLocations: [],
        invalidWordTypedLocations: [],
        win: false,
        selectedWordIndex: -1,
        selectedCell: null,
        boardLoaded: gridToLoad,    // "current" for previous things
        recentPlayedGames: loadPlayHistory(),
        allIndexedWords: everyWord,
        nextHintSeconds: 1,
        puzzleStartTime: Date.now(),
        puzzleEndTime: 0,
        tutorial: getInitTutorialState(tutDone)
    }

    if(!puzzleBitReader)
        return state;

    state = loadGame(state, puzzleBitReader);

    return state;
}

function setCookie(state: FullState) {
    if(state.tutorial.currentStep !== TutorialStep.DONE)
        return;
    let words: Array<WordLocations> = []
    for(let i=0; i<state.userDisplay.words.length; i++) {
        let wd = state.userDisplay.words[i];
        if(wd.solveLevel === SolveLevel.hidden)
            continue;
        words.push(wd);
    }
    let now = Date.now();
    let timerMS = now - state.puzzleStartTime;
    let value: CookieState = {
        gridId: state.gridId,
        words: words,
        hints: state.hintsGiven,
        timerHintDelta: state.nextHintSeconds,
        timerMs: timerMS
    }
    let valString = JSON.stringify(value);
    let base64 = btoa(valString)
    base64 = `${state.gridId}|${timerMS}|${state.nextHintSeconds}|`;
    for(let i=0 ;i<state.hintsGiven.length;i++) {
        let hint = state.hintsGiven[i];
        let horiz = hint.horizontal ? 1: hint.horizontal===null ? 2:0;
        let process = hint.processed ? 1:0;
        let val = `${hint.row},${hint.col},${horiz},${hint.hintType},${process},${hint.letters}|`
        base64 += val;
    }
    base64 += '|';
    for(let i=0; i<words.length; i++) {
        let wd = words[i];
        let horiz = wd.horizontal ? 1:0;
        let val = `${wd.row},${wd.col},${horiz},${wd.solveLevel},${wd.word}|`
        base64 += val;
    }

    let maxAge = (state.boardLoaded === 'current') ? 60*60*24 : 60*60*24*365;

    cookies.set(state.boardLoaded, base64, {
        maxAge: maxAge
    });
}

function loadCookie(board: string): string | null {
    let data = cookies.get(board);
    if(!data)
        return null;
    //let valString = atob(base64);
    //let value: CookieState = JSON.parse(valString) || null;
    return data;
}

function applyCookieToState(state: FullState): boolean {
    if(state.tutorial.currentStep !== TutorialStep.DONE)
        return false;
    let cookie = loadCookie(state.boardLoaded);
    if(!cookie)
        return false;

    try {
        let values = cookie.split("|");
        if(values.length <= 1)
            return false;
        let index =0;
        if(values[index] !== state.gridId) {
            if(state.boardLoaded === "current") //reset the current data cookie
                cookies.set("current", "", {maxAge: 0})
            return false;
        }
        index++;
        let timerMS = Number(values[index]);
        index++;
        let timerHintDelta = Number(values[index]);
        index++;
        //do hints until empty value
        let newHints: Array<HintState> = [];
        for(;index<values.length && values[index];index++) {
            let hintAr = values[index].split(",");
            let horiz = Number(hintAr[2]);
            newHints.push({
                row: Number(hintAr[0]),
                col: Number(hintAr[1]),
                horizontal: horiz<2 ? Boolean(horiz) : null,
                hintType: Number(hintAr[3]),
                processed: Boolean(Number(hintAr[4])),
                letters: hintAr[5]
            })

        }
        //index will stop on the empty string
        index++;
        let newWords: Array<WordLocations> = []
        for(;index<values.length && values[index];index++) {
            let wd = values[index].split(",");
            newWords.push({
                row: Number(wd[0]),
                col: Number(wd[1]),
                horizontal: Boolean(Number(wd[2])),
                solveLevel: Number(wd[3]),
                word: wd[4]
            })
        }



        for(let i=0; i<newWords.length; i++) {
            state.userDisplay.words.push(newWords[i]);
        }
        if(newHints.length > 0)
            state.hintsGiven = newHints;

        let now = Date.now();
        state.nextHintSeconds = timerHintDelta;
        state.puzzleStartTime = now - timerMS;
        return true;
    } catch {
        return false;
    }
}

function processGridCellClick(state: FullState, row: number, col: number) {
    /*
    First, see if they typed in a word and want to add the word
    at a particular location.

    Second, see if a word has been marked bad, and they just want to clear that
    word out

    Third, see if they want to select a word to potentially clear it

    Fourth, see if they want to select the word in the other direction
    */
    if(state.wordTyped) {
        for(let i=0; i<state.wordTypedLocations.length; i++) {
            let wtl = state.wordTypedLocations[i];
            if(wasWordLocationClicked(wtl, row, col)) {
                addWordGuessed(state, wtl);
                return;
            }
        }
    }

    // clear bad word hint
    for(let i=0; i<state.userDisplay.words.length; i++) {
        let word = state.userDisplay.words[i];
        if(word.solveLevel === SolveLevel.doesNotExist || word.solveLevel === SolveLevel.wrongLocation) {
            if(wasWordLocationClicked(word, row, col)) {
                word.solveLevel = SolveLevel.ignore;
                // don't want to clear whatever word was typed thus far
                resetCells(state.userDisplay);
                setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)
                return;
            }
        }
    }

    // clear bad letter hint
    let cell = state.userDisplay.cells[row][col];
    if(cell.condition === CellConditions.incorrect) {
        let cleared = false;
        for(let i=0; i<state.userDisplay.words.length; i++) {
            let word = state.userDisplay.words[i];
            if( (word.solveLevel === SolveLevel.guessed || word.solveLevel === SolveLevel.guessLetter) &&
                wasWordLocationClicked(word, row, col)
            ) {
                word.solveLevel = SolveLevel.ignore;
                cleared = true;
            }
        }
        if(cleared) {
            resetCells(state.userDisplay);
            setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)
            return;
        }
    }

    // select a word
    for(let i=0; i<state.userDisplay.words.length; i++) {
        if(i === state.selectedWordIndex)   //do word in the other direction
            continue;
        let word = state.userDisplay.words[i];
        if(word.solveLevel === SolveLevel.guessed) {
            if(wasWordLocationClicked(word, row, col)) {
                state.selectedWordIndex = i;
                state.selectedCell = null;
                // don't want to clear whatever word was typed thus far
                clearWordTypedResetGrid(state);
                return;
            }
        }
    }
    state.selectedWordIndex = -1;
    let cellClicked: CellLocation = {row: row, col: col}
    let lettersAvailable: string = "";
    if(state.selectedCell === cellClicked) {
        state.selectedCell = null;
        enableAllLetters(state.typeButtons);
    }
    else {
        switch(state.userDisplay.cells[row][col].condition) {
            case CellConditions.blocked:    //can't select blocked
            case CellConditions.confirmed:  //can't select confirmed
            case CellConditions.suggested:  //shouldn't be possible
            case CellConditions.incorrect:  //shouldn't be possible
            case CellConditions.selected:   //toggle off
            case CellConditions.hideSelected: //toggle off
                state.selectedCell = null;
                enableAllLetters(state.typeButtons);
                break;
            case CellConditions.filled:
                state.selectedCell = cellClicked;
                enableAvailableLetters("", state.typeButtons);
                break;
            case CellConditions.lettersAvailable:
            case CellConditions.blank:
            default:
                state.selectedCell = cellClicked;
                state.wordTyped = "";
                state.invalidWordTypedLocations = [];
                lettersAvailable = getAvailableLetters(state, row, col);
                enableAvailableLetters(lettersAvailable, state.typeButtons)

        }
    }

    resetCells(state.userDisplay);
    setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)
}

function addWordGuessed(state: FullState, word: WordLocations) {
    // assumes that the word fits...
    word.solveLevel = SolveLevel.guessed;
    state.userDisplay.words.push(word);
    clearWordTypedResetGrid(state);
    setCookie(state);
    processWin(state, true);
}

function addLetterGuessed(state: FullState, word: WordLocations) {
    // assumes that the word fits...
    word.solveLevel = SolveLevel.guessLetter;
    state.userDisplay.words.push(word);
    state.selectedCell = null;
    state.selectedWordIndex = -1;
    clearWordTypedResetGrid(state);
    setCookie(state);
    processWin(state, true);
}

function clearSelectedWord(state: FullState, includeSingleLetter: boolean): boolean {
    let cleared = false;
    if(state.selectedWordIndex>=0 && state.selectedWordIndex<state.userDisplay.words.length) {
        let wd = state.userDisplay.words[state.selectedWordIndex];
        switch(wd.solveLevel) {
            case SolveLevel.doesNotExist:
            case SolveLevel.wrongLocation:
            case SolveLevel.guessed:
            case SolveLevel.guessLetter:
                wd.solveLevel = SolveLevel.ignore;
                cleared=true;
                break;
            default:
                break;
        }
        state.selectedWordIndex = -1;
        state.selectedCell = null;
    }

    if(state.selectedCell !== null && includeSingleLetter) {
        for(let i=0; i<state.userDisplay.words.length; i++) {
            let wd = state.userDisplay.words[i];
            if(wd.solveLevel !== SolveLevel.guessLetter)
                continue;
            if(wd.col === state.selectedCell.col && wd.row === state.selectedCell.row) {
                wd.solveLevel = SolveLevel.ignore;
                cleared=true;
                break;
            }
        }
        state.selectedCell = null;
        state.selectedWordIndex = -1;
    }

    return(cleared);
}

type LoadBoardReturns = {
    fileBytes: Array<number> | null;
    board: string
}

export const loadBoard = createAsyncThunk(
    "everything/load_board",
    async (data: string, thunkAPI): Promise<LoadBoardReturns> => {
        let puzzleBitReader: BitReader | null = await getGridFileReader(data);
        if(puzzleBitReader === null)
            return {fileBytes: null, board: data};
        let serializable: Array<number> = [].slice.call(puzzleBitReader.input);
        return {fileBytes: serializable, board: data};
    }
)

export const fullpageSlice = createSlice({
    name: 'everything',
    initialState: await getInitialState(),
    reducers: {
        toggleBlur: (state: FullState) => {
            state.userDisplay.blur = !state.userDisplay.blur;
        },
        setTimerId: (state: FullState, action: PayloadAction<NodeJS.Timeout>) => {
            /*
            if(state.timers.timerId !== null) {
                clearInterval(state.timers.timerId);
                state.timers.timerId = null;
            }
            state.timers.timerId = action.payload;
            */
        },
        updateTimer: (state: FullState) => {
            if(state.win) {
                //stopTimer(state.timers);
                return;
            }
        },
        clearTimer: (state: FullState) => {
            //stopTimer(state.timers)
        },
        generateHint: (state: FullState) => {
            if(state.win) return;

            if(!isTutorialActionAllowed(state.tutorial.currentStep, "generateHint", ""))
                return;

            let hint: HintState | null = generateRandomHint(state.userDisplay, state.finalSolution, state.selectedWordIndex);
            if(hint === null)
                return;
            state.nextHintSeconds = Math.min(15, state.nextHintSeconds + 0.5)

            state.hintsGiven.push(hint);
            if(hint.hintType === HintType.suggestWord) {
                state.wordTyped = hint.letters.toLowerCase()
                disableUsedLetters(state.wordTyped, state.typeButtons);
                let ret = findSuggestedWordLocations(state.userDisplay, state.wordTyped, state.hintsGiven)
                state.wordTypedLocations = ret.good;
            }
            state.selectedWordIndex = -1;
            state.selectedCell = null;
            disableUsedLetters(state.wordTyped, state.typeButtons);
            resetCells(state.userDisplay);
            setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)
            processWin(state, true);
            setCookie(state);
            if(isStateAllowNextTutorialStep(state.tutorial.currentStep, "generateHint"))
                state.tutorial.currentStep = getNextStep(state.tutorial.currentStep);
        },
        gridCellClick: (state: FullState, action: PayloadAction<CellLocation>) => {
            if(state.win) return;
            let row = action.payload.row;
            let col = action.payload.col;
            if(!isTutorialActionAllowed(state.tutorial.currentStep, "gridCellClick", `${row}_${col}`))
                return;

            processGridCellClick(state, row, col);
            if(isStateAllowNextTutorialStep(state.tutorial.currentStep, `${row}_${col}`))
                state.tutorial.currentStep = getNextStep(state.tutorial.currentStep);
        },
        attemptAddWord: (state: FullState) => {
            if(state.win) return;
            let tutWord = state.wordTyped;
            if(!isTutorialActionAllowed(state.tutorial.currentStep, "attemptAddWord", tutWord))
                return;
            if(state.wordTyped && state.wordTypedLocations.length === 1) {
                addWordGuessed(state, state.wordTypedLocations[0]);
                if(isStateAllowNextTutorialStep(state.tutorial.currentStep, `add_${tutWord}`))
                    state.tutorial.currentStep = getNextStep(state.tutorial.currentStep);
                return;
            }
            if(isStateAllowNextTutorialStep(state.tutorial.currentStep, `add_${tutWord}`))
                state.tutorial.currentStep = getNextStep(state.tutorial.currentStep);
            clearWordTypedResetGrid(state)
        },
        backLetter: (state: FullState) => {
            if(state.win) return;

            if(!isTutorialActionAllowed(state.tutorial.currentStep, "backLetter", ""))
                return;

            if(clearSelectedWord(state, true)) {
                clearWordTypedResetGrid(state)
                return;
            }

            if(!!!state.wordTyped) return;

            state.wordTyped = state.wordTyped.slice(0, -1)
            disableUsedLetters(state.wordTyped, state.typeButtons)
            state.selectedWordIndex = -1;
            state.selectedCell = null;
            let suggestions = findSuggestedWordLocations(state.userDisplay, state.wordTyped, state.hintsGiven);
            state.wordTypedLocations = suggestions.good;
            state.invalidWordTypedLocations = suggestions.bad;
            resetCells(state.userDisplay);
            setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)

        },
        clearWordTyped: (state: FullState) => {
            if(state.win) return;

            let wdTut  = "";
            if(state.tutorial.currentStep !== TutorialStep.DONE) {

                if(state.selectedWordIndex>=0 && state.selectedWordIndex<state.userDisplay.words.length)
                    wdTut = state.userDisplay.words[state.selectedWordIndex].word;
                else if (state.selectedCell !== null)
                    wdTut = state.userDisplay.cells[state.selectedCell.row][state.selectedCell.col].text || "";
                if(!!!wdTut)
                    wdTut = state.wordTyped
                if(!isTutorialActionAllowed(state.tutorial.currentStep, "clearWordTyped", wdTut.toLowerCase()))
                    return;
            }

            clearSelectedWord(state, true);
            clearWordTypedResetGrid(state)
            if(isStateAllowNextTutorialStep(state.tutorial.currentStep, wdTut))
                state.tutorial.currentStep = getNextStep(state.tutorial.currentStep);
        },
        addLetterWordType: (state: FullState, action: PayloadAction<number> ) => {
            //there are sensitivity issues, so this function is called for
            //on click, on double click, and on touch end. This means that
            // this may be called potentially 5x in the event of a double click:
            // 2 touch end, 2 single click, 1 double click.
            if(state.win) return;
            let now = new Date().getTime();
            let index = action.payload;
            let btn = state.typeButtons[index];

            //assume all processing happens in under half a second from the first click
            if(now <= btn.lastClickTime+500)
                return;
            let letter = btn.letter.toLowerCase();
            if(state.wordTyped.indexOf(letter) >= 0)    //old version, safeguard prevent double letter entry
                return;
            let tutWord = state.wordTyped + letter;
            if(!isTutorialActionAllowed(state.tutorial.currentStep, "addLetterWordType", tutWord))
                return;

            if(state.selectedCell !== null) {
                let cell = state.userDisplay.cells[state.selectedCell.row][state.selectedCell.col];
                if(
                    cell.condition === CellConditions.blank ||
                    cell.condition === CellConditions.lettersAvailable ||
                    cell.condition === CellConditions.hideSelected
                ) {
                    addLetterGuessed(state, {
                        row: state.selectedCell.row,
                        col: state.selectedCell.col,
                        word: letter,
                        horizontal: true,
                        solveLevel: SolveLevel.guessLetter
                    });
                    enableAllLetters(state.typeButtons);
                    state.wordTyped="";
                    //flag to ignore the double action
                }
            }
            else {
                state.wordTyped += state.typeButtons[index].letter.toLowerCase();
                state.typeButtons[index].enabled = false;
                let suggestions = findSuggestedWordLocations(state.userDisplay, state.wordTyped, state.hintsGiven)
                state.wordTypedLocations = suggestions.good;
                state.invalidWordTypedLocations = suggestions.bad;
            }

            state.selectedWordIndex = -1;
            state.selectedCell = null;
            btn.lastClickTime = now;
            resetCells(state.userDisplay);
            setGridCellStates(state.userDisplay, state.hintsGiven, state.wordTypedLocations, state.selectedWordIndex, state.selectedCell, state.invalidWordTypedLocations)
            if(isStateAllowNextTutorialStep(state.tutorial.currentStep, tutWord))
                state.tutorial.currentStep = getNextStep(state.tutorial.currentStep)
        },
        nextTutorialState: (state: FullState) => {
            state.tutorial.currentStep = getNextStep(state.tutorial.currentStep)
            if(state.tutorial.currentStep === TutorialStep.DONE) {
                cookies.set("tutorial", "DONE", {maxAge: 60*60*24*365})
            }
        },
        skipTutorialState: (state: FullState) => {
            state.tutorial.currentStep = TutorialStep.DONE;
            cookies.set("tutorial", "DONE", {maxAge: 60*60*24*365})
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadBoard.pending, (state,action) => {})
        builder.addCase(loadBoard.fulfilled, (state: FullState, action: PayloadAction<LoadBoardReturns>) => {
            if(action.payload.fileBytes === null)
                return;
            if(state.boardLoaded === action.payload.board)  //this puzzle was already loaded!
                return;
            if(!isTutorialActionAllowed(state.tutorial.currentStep, "loadBoard", action.payload.board))
                return;
            let data = new Uint8Array(action.payload.fileBytes)
            let br = new BitReader(data)
            state.boardLoaded = action.payload.board;
            loadGame(state, br);
        })
    },
})

export const {clearWordTyped, backLetter, addLetterWordType, attemptAddWord, gridCellClick, generateHint, updateTimer, clearTimer, setTimerId, nextTutorialState, skipTutorialState, toggleBlur} = fullpageSlice.actions;
export const fullpageReducer = fullpageSlice.reducer;
