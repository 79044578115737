import { InitState } from "../features/fullpageSlice";
import { CellLocation } from "../features/grid/cell/cellSlice";
import { SolveLevel } from "../features/solution";
import { WordLocations } from "../features/words";

export function loadGrid(data: BitReader) {
    let v: string = data.readBits(8);
    let version: number = parseInt(v, 2);
    switch(version) {
        case 1:
        default:
            return loadGridV1(data);
    }
}

function loadUUID(data: BitReader) {
    let v: string = data.readBits(16*8);
    let uuid: string = ""
    let uuidStart = 0;
    let uuidSize = 4*8; // first 8 characters
    let d: string = parseInt(v.slice(uuidStart,uuidSize), 2).toString(16).padStart(8, "0");
    uuid += d + "-";

    uuidStart += uuidSize;
    uuidSize = 2*8; // next 4 characters
    d = parseInt(v.slice(uuidStart, uuidStart + uuidSize), 2).toString(16).padStart(8, "0");
    uuid += d + "-";

    uuidStart += uuidSize;
    d = parseInt(v.slice(uuidStart, uuidStart + uuidSize), 2).toString(16).padStart(8, "0");
    uuid += d + "-";

    uuidStart += uuidSize;
    d = parseInt(v.slice(uuidStart, uuidStart + uuidSize), 2).toString(16).padStart(8, "0");
    uuid += d + "-";

    uuidStart += uuidSize;
    uuidSize = 6*8;
    d = parseInt(v.slice(uuidStart, uuidStart + uuidSize), 2).toString(16).padStart(8, "0");
    uuid += d;
    return uuid;
}

function loadGridV1(data: BitReader) {
    const LETTER_INDICES = 'mgu shzxyrvawdteplcfqkiobjn' // all 26 letters plus space, randomized
    let gridId: string = loadUUID(data);

    let v = data.readBits(1);
    let _uniqueColumns: boolean = Boolean(v==="1");

    v = data.readBits(4);
    let numRows: number = parseInt(v, 2);

    v = data.readBits(4);
    let numCols: number = parseInt(v,2);

    v = data.readBits(4);
    let numLetters: number = parseInt(v, 2);

    let letterIndex = 0;
    let acceptableLetters = "";
    let letterEncode: Record<number, string> = {};
    for (let nthLetter = 0; nthLetter < numLetters; nthLetter++) {
        v = data.readBits(5);
        let delta = parseInt(v,2);
        letterIndex += delta;
        let foundLetter = LETTER_INDICES[letterIndex];
        letterEncode[nthLetter] = foundLetter;
        if(foundLetter !== " ")
            acceptableLetters += foundLetter;
    }
    letterEncode[numLetters] = " ";

    let encodeRowSize = (numRows-1).toString(2).length;
    let encodeColSize = (numCols-1).toString(2).length;
    let encodeSize = numLetters.toString(2).length;

    v = data.readBits(5);
    let numStartSize = parseInt(v, 2);
    let startGridCells: Array<CellLocation> = []
    for(let i=0; i<numStartSize; i++) {
        v = data.readBits(encodeRowSize);
        let row = parseInt(v, 2);
        v = data.readBits(encodeColSize);
        let col = parseInt(v, 2);
        startGridCells.push({row: row, col: col});
    }

    let words: Array<WordLocations> = []

    while(!data.fileFinished()) {
        v = data.readBits(encodeRowSize);
        let row = parseInt(v, 2);
        v = data.readBits(encodeColSize);
        let col = parseInt(v, 2);
        let horizontal = Boolean(data.readBits(1) === "1")
        let word = ""
        while(!data.fileFinished()) {
            v = data.readBits(encodeSize);
            let enc = letterEncode[parseInt(v,2)]
            if (enc === " ")
                break;
            word += enc;
        }

        if (word.length>1 || !data.fileFinished()) {
            words.push({
                "word": word,
                "row": row,
                "col": col,
                "horizontal": horizontal,
                "solveLevel": SolveLevel.confirmed
            })
        }
    }

    //put in alphabetical order
    acceptableLetters = acceptableLetters.split('').sort().join('');

    let initState: InitState = {
        finalSolution: {
            cells: [],
            numRows: numRows,
            numCols: numCols,
            words: words,
            blur: false,
        },
        lettersAvailable: acceptableLetters,
        gridId: gridId,
        startCells: startGridCells
    }

    return initState
}

export async function getGridFileReader(preferredBoard: string) {

    let fileLocations: Array<string> = [
        `./history/${preferredBoard}.board`, // if alphique.com/play/index.html
        `./play/history/${preferredBoard}.board`, // if alphique.com/index.html
        process.env.REACT_APP_INIT_PUZZLE_LOAD_FILE || '',
        process.env.REACT_APP_INIT_PUZZLE_LOAD_URL || ''
    ]
    let data2: Blob | null = null;
    for(let i=0; i<3; i++) {
        try {
            let fl = fileLocations[i];
            if(!fl || fl === "./history/current.board")
                continue
            data2 = await fetch(fl) // works with file system and URLs. Sweet.
            .then( res => {
                //let cl = res.clone();
                //let t = res.text()
                //console.log(t)
                return res.blob();
            })
            //.then( res => res.blob())
            //console.log(data2);
            if (data2?.type.toLowerCase() === 'text/html')
                continue; // we're not loading a website.
            if(data2?.type.toLowerCase() === "application/octet-stream")
                break;
            if(data2?.type.toLowerCase() === "binary/octet-stream")
                break;
        } catch (e){
            //console.log(e);
        }
    }
    if(data2 === null)
        return null;


    let blob = new Uint8Array(await data2.arrayBuffer());
    let bd = new BitReader(blob);
    return bd;

}

export class BitReader {
    input: Uint8Array;
    bits: string;
    cursor: number;
    size: number;
    readEncoding: Record<string, string>;

    constructor(data: Uint8Array) {
        this.input = data;
        this.bits = "";
        this.cursor = 0;
        this.size = 0;
        this.readEncoding = {}
        this.expandData();

    }

    fileFinished() {
        return (this.cursor >= this.size);
    }

    setEncoding(writeEncoding: Record<string,string>) {
        const flipped = Object.entries(writeEncoding).map(([k,v]) => [v,k])
        this.readEncoding = Object.fromEntries(flipped);
    }

    readEncoded(writeEncoding: Record<string,string>) {
        this.setEncoding(writeEncoding);
        this.expandData();
        let output: string = "";
        let tmpRead: string = "";
        for(let i=0;i<this.bits.length; i++) {
            tmpRead += this.bits[i]
            if(tmpRead in this.readEncoding) {
                output += this.readEncoding[tmpRead];
                tmpRead = "";
            }
        }
        if(tmpRead.length > 8)
            console.log("Bad Encoding on file for words");
        return output;
    }

    expandData() {
        this.bits = "";
        this.cursor = 0;
        for(let i=0; i<this.input.length; i++) {
            let d: number = this.input[i];
            let s: string = d.toString(2);
            s = s.padStart(8, "0")
            this.bits += s;
        }
        this.size = this.bits.length;
    }

    setCursor(bitCursor: number | null = null) {
        this.cursor = Math.max(0, Math.min(bitCursor || 0, this.size));
    }

    readBits(numBits: number=1) {
        if(numBits < 1)
            throw new Error("numBits must be >1")
        let start = this.cursor;
        let stop = start + numBits;
        let data = "";
        if (start === this.size)
            data = "".padStart(numBits, "0");
        else if (stop >= this.size)
            data = this.bits.slice(start, this.size).padEnd(numBits, "0");
        else
            data = this.bits.slice(start,stop);

        this.setCursor(this.cursor + numBits)
        return data;

    }
}

export const ENCODING_FULL_DELIMIT_VAR: Record<string,string> = {
    "\n": "0000",
    "A": "0010",
    "B": "110010",
    "C": "10011",
    "D": "10100",
    "E": "0001",
    "F": "110100",
    "G": "110001",
    "H": "10110",
    "I": "0011",
    "J": "111001",
    "K": "110011",
    "L": "10001",
    "M": "10101",
    "N": "0110",
    "O": "0101",
    "P": "10111",
    "Q": "111010",
    "R": "0100",
    "S": "10000",
    "T": "0111",
    "U": "10010",
    "V": "110110",
    "W": "110101",
    "X": "110111",
    "Y": "110000",
    "Z": "111000",
}

export const ENCODING_FULL_COMPRESS_VAR: Record<string,string> = {
    "A": "10011",
    "B": "11111011",
    "C": "11100",
    "D": "11010",
    "E": "011",
    "F": "11111100",
    "G": "11101",
    "H": "11110",
    "I": "10001",
    "J": "1111111110",
    "K": "11111010",
    "L": "10110",
    "M": "11111000",
    "N": "10010",
    "O": "11000",
    "P": "11111001",
    "Q": "1111111111",
    "R": "10100",
    "S": "10111",
    "T": "10101",
    "U": "11011",
    "V": "11111101",
    "W": "11111110",
    "X": "1111111100",
    "Y": "11001",
    "Z": "1111111101",
    "(": "001",
    "[": "10000",
    "]": "000",
    "{": "010",
}