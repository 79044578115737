import './winScreenStyle.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { millisecondsToString } from '../timer/timerSlice';
import { getHintScore, getTimerScore, getWordScore } from './winScrenSlice';

export function WinTable() {

    const timerStart = useSelector((state: RootState) => state.fullpage.puzzleStartTime);
    const timerEnd = useSelector((state: RootState) => state.fullpage.puzzleEndTime);
    const lettersAvailable = useSelector((state: RootState) => state.fullpage.lettersAvailable)
    const boardLoaded = useSelector((state: RootState) => state.fullpage.boardLoaded)
    const wordSolution = useSelector((state: RootState) => state.fullpage.finalSolution.words);
    const words = useSelector((state: RootState) => state.fullpage.userDisplay.words);
    const hints = useSelector((state: RootState) => state.fullpage.hintsGiven);

    let timeSpent = (timerEnd - timerStart);
    let timeDisplay = millisecondsToString(timeSpent, 2)

    let timerScore = getTimerScore(timeSpent);
    let hintScore: number = getHintScore(hints);
    let wordScore = getWordScore(words);
    let totScore = timerScore + hintScore + wordScore;

    let lAvail = lettersAvailable.toUpperCase()

    let wordsSub = `${lAvail} - ${wordSolution.length}`
    let hintSub = `Starting ${lettersAvailable.length}`

    return (
        <table className='scoreTable animateTable'><tbody>
            <tr>
                <td colSpan={3}><span className='winStyle'>ALPHIQUE WINNER!</span></td>
            </tr>
            <tr className='timerScore'>
                <td>Time</td>
                <td>({timeDisplay})</td>
                <td className='sidePadding'>{timerScore}</td>
            </tr>
            <tr className='hintScore'>
                <td>Hints</td>
                <td>{hintSub}</td>
                <td className='sidePadding'>{hintScore}</td>
            </tr>
            <tr className='wordScore'>
                <td>Words</td>
                <td>{wordsSub}</td>
                <td className='sidePadding'>{wordScore}</td>
            </tr>
            <tr className='totalScore'>
                <td><b>Total</b></td>
                <td>{boardLoaded}</td>
                <td className='sidePadding'><b>{totScore}</b></td>
            </tr>
        </tbody></table>
    )
}