import './winScreenStyle.css'
//import html2canvas from 'html2canvas'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../app/store'

import { HintType } from '../userInput/hints/hintSlice';

import { toggleBlur } from '../fullpageSlice';
import { useRef, useState } from 'react';
import { getDefinition } from '../popout/wordDefsSlice';
import { WinTable } from './winTable';
//import { captureElement } from './copy';
import { CellConditions, CellLocation } from '../grid/cell/cellSlice';
import { getHintScore, getTimerScore, getWordScore } from './winScrenSlice';
import { millisecondsToString } from '../timer/timerSlice';

export function WinScreen() {
    const winState = useSelector((state: RootState) => state.fullpage.win);
    const hints = useSelector((state: RootState) => state.fullpage.hintsGiven);
    const wordSolution = useSelector((state: RootState) => state.fullpage.finalSolution.words);
    const blur = useSelector((state: RootState) => state.fullpage.userDisplay.blur)
    const cells = useSelector((state: RootState) => state.fullpage.finalSolution.cells)
    const boardLoaded = useSelector((state: RootState) => state.fullpage.boardLoaded)
    const timerStart = useSelector((state: RootState) => state.fullpage.puzzleStartTime);
    const timerEnd = useSelector((state: RootState) => state.fullpage.puzzleEndTime);
    const lettersAvailable = useSelector((state: RootState) => state.fullpage.lettersAvailable)
    const words = useSelector((state: RootState) => state.fullpage.userDisplay.words);

    const dispatch = useDispatch<AppDispatch>();

    const [wordSelected, setWordSelected] = useState(-1)


    const exportRef = useRef(null);

    if(!winState)
        return (<></>); // don't show anything if you didn't win yet



    let wordDisplay = []
    let sortedWords=[]
    for(let i=0; i<wordSolution.length; i++)
        sortedWords.push(wordSolution[i].word.toUpperCase())

    sortedWords = sortedWords.sort((a: string, b: string) => {
        let x = b.length - a.length;
        if(x===0){
            for(let i=0;i<a.length; i++) {
                x = a.charCodeAt(i) - b.charCodeAt(i)
                if(x!==0)
                    return x;
            }
        }
        return x
    })

    for(let i=0; i<sortedWords.length; i++)
        wordDisplay.push((
            <option
                key={`words_${i}`}
                className="myOption"
                value={i}
            >{sortedWords[i]}</option>
        ))

    let hintsGiven = []
    for(let i=0; i<hints.length; i++) {
        let h = hints[i];
        let dispText: string = "";
        let horizontal = h.horizontal ? ">" : "v"
        switch(h.hintType) {
            case HintType.badLetter:
                dispText=`Bad Letter: (${h.row+1},${h.col+1}) ${h.letters.toUpperCase()}`;
                break;
            case HintType.badLocationWord:
                dispText=`Bad Location: (${h.row+1},${h.col+1}, ${horizontal}) ${h.letters.toUpperCase()}`
                break;
            case HintType.badWord:
                dispText=`Bad Word: (${h.row+1},${h.col+1}, ${horizontal}) ${h.letters.toUpperCase()}`
                break;
            case HintType.goodLetter:
                dispText=`Good Letter: (${h.row+1},${h.col+1}) ${h.letters.toUpperCase()}`
                break;
            case HintType.goodWord:
                dispText=`Good Word: (${h.row+1},${h.col+1}, ${horizontal}) ${h.letters.toUpperCase()}`
                break;
            case HintType.suggestWord:
                dispText=`Suggestion: ${h.letters.toUpperCase()}`
                break;
        }
        hintsGiven.push((
            <option key={`hints_${i}`} className='myOption'>{dispText}</option>
        ))
    }

    let defn: string;
    if(wordSelected < 0 || wordSelected >= sortedWords.length) //bug if prior win had lots of words and is out of bounds
        defn = "Some word/acronym/abbreviation definitions available";
    else {
        let word = sortedWords[wordSelected];
        defn = getDefinition(word, "Definition not available")
    }

    let blurStr: string;
    if(blur)
        blurStr = "Show Solution";
    else
        blurStr = "Hide Solution";

    function copyTextWin() {
        let timeSpent = (timerEnd - timerStart);
        let timeDisplay = millisecondsToString(timeSpent, 2)
        let timerScore = getTimerScore(timeSpent);
        let hintScore: number = getHintScore(hints);
        let wordScore = getWordScore(words);
        let totScore = timerScore + hintScore + wordScore;
        let lAvail = lettersAvailable.toUpperCase()
        let hintCoords: Array<CellLocation> = [];
        for(let i=0; i<lAvail.length && i<hints.length; i++) {
            if(hints[i].hintType===HintType.goodLetter) {
                hintCoords.push({
                    row: hints[i].row,
                    col: hints[i].col
                });
            }
        }

        let asciiBoard = `Alphique Winner!\n`
        for(let ixRow=0;ixRow<cells.length; ixRow++) {
            for(let ixCol=0; ixCol < cells[ixRow].length; ixCol++) {
                let foundHint = false;
                switch(cells[ixRow][ixCol].condition) {
                    case CellConditions.blocked:
                        asciiBoard += "⬛" // 🟦
                        break;
                    case CellConditions.confirmed:
                        for(let ixHint=0; ixHint < hintCoords.length; ixHint++) {
                            if(hintCoords[ixHint].row === ixRow && hintCoords[ixHint].col === ixCol) {
                                asciiBoard += "🟧";
                                foundHint=true;
                            }
                        }
                        if(!foundHint)
                            asciiBoard += "⬜";
                        break;
                    default:
                        asciiBoard += "⬜";
                        break;
                }
            }
            asciiBoard += "\n"
        }
        asciiBoard += `${boardLoaded} - ${lAvail}\nScore: ${totScore} (${timeDisplay})`
        navigator.clipboard.writeText(asciiBoard);
    }

    return (
        <div className='youWin' >
            <div  ref={exportRef} className='winTableClipboard'><WinTable /></div>

            <table className='scoreTable'><tbody>
            <tr>
                    <td>
                        <select onChange={(e) => {
                            let val = Number(e.target.value)
                            setWordSelected(val)}
                        }>
                            <option value={-1}>Words / Abbr. / Accr.</option>
                            {wordDisplay}
                        </select>
                    </td>
                    <td><select>
                            <option>Hints</option>
                            {hintsGiven}
                        </select></td>
                </tr>
            </tbody></table>
            <span className='nextPuzzle'>New puzzle available at midnight</span>
            <span className='nextPuzzle'>Try past puzzles under Menu -&gt; History</span>
            <span className='nextPuzzle'>{defn}</span>
            <table className='scoreTable hideSolutionAnimation'><tbody><tr>
                <td>
                    <button
                        className='hideSolutionButton'
                        onClick={(e) => {
                            dispatch(toggleBlur());
                        }}
                    >{blurStr}</button>
                </td>
                <td>
                    <button
                        className='hideSolutionButton'
                        onClick={(e) => {
                            //captureElement(exportRef);
                            copyTextWin();
                        }}
                    >Share (Clipboard)</button>
                </td>

            </tr></tbody></table>
        </div>
    )

}